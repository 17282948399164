<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body">
			<div class="center">
				<div class="ucenter_main">
					<ucMenu num="9"></ucMenu>
					<div class="right_con">
						<div class="rc_main" v-loading="isLoading">
							<p class="fb">员工管理</p>
							<div class="select_wrap">
								<el-input class="w290" v-model="searchData.name" placeholder="请输入员工名称" clearable>
									<p slot="prefix" class="label_title">员工名称</p>
								</el-input>
								<el-input class="w290" v-model="searchData.mobile" placeholder="请输入员工手机号" clearable>
									<p slot="prefix" class="label_title">员工手机号</p>
								</el-input>
								<el-select class="w290" v-model="searchData.role_id" placeholder="请选择岗位" clearable filterable >
									<p slot="prefix" class="label_title">岗位类型</p>
									<el-option :value="item.val" :label="item.key" v-for="(item,index) in roleList" :key="index">{{item.key}}</el-option>
								</el-select>
								<el-select class="w290" v-model="searchData.status" placeholder="请选择状态" clearable filterable >
									<p slot="prefix" class="label_title">禁用状态</p>
									<el-option :value="1" label="启用">启用</el-option>
									<el-option :value="0" label="禁用">禁用</el-option>
								</el-select>
								<el-button plain size="small" @click="getList()">查询</el-button>
								<el-button plain size="small" @click="resetSearch()">重置</el-button>
							</div>
							<div class="totals_btn">
								<el-button plain type="primary" size="small" @click="employeeOpen('1')">新增员工</el-button>
								<el-button plain size="small" @click="employeeDel">删除员工</el-button>
							</div>
							<el-table class="rc_table" highlight-current-row @selection-change="selection"  row-key="id" :data="list" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
								<el-table-column align="center" type="selection" width="50"></el-table-column>
								<el-table-column align="center"  property="name" label="员工姓名" show-overflow-tooltip width=""></el-table-column>
								<el-table-column align="center"  property="mobile" label="员工手机号" show-overflow-tooltip width=""></el-table-column>
								<el-table-column align="center"  property="role.name" label="岗位类型" show-overflow-tooltip width=""></el-table-column>
								<el-table-column align="center"  property="note" label="备注" show-overflow-tooltip width=""></el-table-column>
								<el-table-column align="center"  property="date" label="创建时间" show-overflow-tooltip width=""></el-table-column>
								<el-table-column align="center"  property="scope" label="状态" show-overflow-tooltip width="">
									<template slot-scope="scope">
										<el-switch :active-value="1" :inactive-value="0" v-model="scope.row.status" @change="employeeStatus(scope.row)"></el-switch>
										<span style="margin-left:6px">{{scope.row.status=='1'?'启用':'禁用'}}</span>
									</template>
								</el-table-column>
								<el-table-column label="操作" align="center" width="180">
									<template slot-scope="scope">
										<el-button size="mini" type="primary" @click="employeeOpen('2',scope.row.id)" >修改</el-button>
										<el-button size="mini" @click="employeeDel(scope.row)" >删除</el-button>
									</template>
								</el-table-column>
							</el-table>
							<Pagination :total="page_data.total" :page.sync="page_data.page" :limit.sync="page_data.limit" @pagination="getList" v-if="page_data.total>page_data.limit" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<myFoot></myFoot>
		<!-- 新增/修改员工 -->
		<el-dialog :title="employeeData.title" :visible.sync="employeeData.isShow" width="30%" @close='closeMask'>
			<el-form label-width="100px" :model="employeeData.data">
				<el-form-item label="员工名称" required>
					<el-input size="small" v-model="employeeData.data.name" placeholder="请输入员工名称"></el-input>
				</el-form-item>
				<el-form-item label="员工手机号" required>
					<el-input size="small" v-model="employeeData.data.mobile" placeholder="请输入员工手机号"></el-input>
				</el-form-item>
				<el-form-item label="密码" required>
					<el-input size="small" v-model="employeeData.data.password" placeholder="请输入密码"></el-input>
				</el-form-item>
				<el-form-item label="岗位类型" required>
					<el-select class="w100" v-model="employeeData.data.role_id" placeholder="请选择岗位" clearable filterable >
						<el-option :value="item.val" :label="item.key" v-for="(item,index) in roleList" :key="index">{{item.key}}</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注">
					<el-input size="small" v-model="employeeData.data.note" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="是否禁用">
					<el-switch v-model="employeeData.data.status" :active-value="1" :inactive-value="0" active-color="#13ce66"></el-switch>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='closeMask'>取消</el-button>
				<el-button size="small" type="primary" class="title" @click="saveEdit(employeeData.openType)">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
import { Loading } from 'element-ui';
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import Pagination from '../../components/pagination/pagination';
import ucMenu from '../../components/ucenterMenu/ucmenu';
import common from '../../assets/js/utils/common.js';
export default {
	name: "ucenterEmployeeManage",
	components:{
		myHead,myFoot,ucMenu,Pagination
	},
	data() {
		return {
			isLoading: false,
			searchData:{},
			page_data: {
				limit: 10,
				page: 1,
				total:0,
			},
			employeeData:{
				title:"",
				isShow:false,
				data:{
					name:"",
					mobile:"",
					password:"",
					role_id:"",
					note:"",
					status:1,
				},
				id:"",
			},
			list:[],
			roleList:[],
			ids:[],
		}
	},
	created() {
		this.getRoleList()
		this.getList()
	},
	methods: {
		...mapActions({
			getAgentuserIndex:"ucenter/getAgentuserIndex",
			getAgentuserFieldList:"ucenter/getAgentuserFieldList",
			opAgentuserAdd:"ucenter/opAgentuserAdd",
			opAgentuserDel:"ucenter/opAgentuserDel",
			getAgentuserInfo:"ucenter/getAgentuserInfo",
			opAgentuserUpdate:"ucenter/opAgentuserUpdate",
			opAgentuserExt:"ucenter/opAgentuserExt",
		}),
		employeeOpen(type,id){//打开员工弹窗
			this.employeeData.isShow = true
			this.employeeData.openType = type
			if(type==1){
				this.employeeData.title = "新增员工"
			}else if(type==2){
				this.employeeData.title = "修改员工"
				this.employeeData.id = id
				this.getAgentuserInfo({data:{id:id},success:(res)=>{
					if(res.status==200){
						console.log('getAgentuserInfo',res)
						this.employeeData.data = res.data
					} else {
						this.$message({message: res.msg,type: 'error',});
					}
					this.isLoading = false
				}})
			}
		},
		employeeDel(row){//员工删除
			this.$confirm('确定操作吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let ids = row.id ? row.id : this.ids.join(',')
				this.opAgentuserDel({data:{id:ids},success:(res)=>{
					if(res.status==200){
						// console.log('del',res)
						this.$message({type: 'success',message: res.msg})
						this.getList()
					} else {
						this.$message({message: res.msg,type: 'error',});
					}
					this.isLoading = false
				}})
			}).catch(() => {})
		},
		getList(showLoading = true){//获取员工列表
			if(showLoading) this.isLoading = true
			let param = {limit:this.page_data.limit,page:this.page_data.page}
			Object.assign(param, this.searchData)
			this.getAgentuserIndex({data:param,success:(res)=>{
				if(res.status==200){
					console.log('getAgentuserIndex',res)
					let data = res.data.data
					for(let i in data){
						data[i].date = common._date(data[i].createtime,'Y-m-d H:i:s')
					}
					this.list = data
					this.page_data.total = res.data.total
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
				this.isLoading = false
			}})
		},
		getRoleList(){//获取岗位列表
			this.getAgentuserFieldList({data:{},success:(res)=>{
				if(res.status==200){
					console.log('getAgentuserFieldList',res)
					this.roleList = res.data.role_ids
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		resetSearch(){//重置搜索
			this.searchData = {}
			this.getList()
		},
		closeMask(){//关闭弹窗
			this.employeeData = this.$options.data().employeeData
		},
		saveEdit(type){
			if(!this.employeeData.data.name){
				this.$message({type: 'info',message: "请输入员工姓名！"})
				return false
			}
			if(!this.employeeData.data.mobile){
				this.$message({type: 'info',message: "请输入员工手机号！"})
				return false
			}
			if(!common._verify_mobile(this.employeeData.data.mobile)){
				this.$message({type: 'info',message: "请输入正确的手机号！"})
				return false
			}
			if(!this.employeeData.data.password){
				this.$message({type: 'info',message: "请输入密码！"})
				return false
			}
			if(!this.employeeData.data.role_id){
				this.$message({type: 'info',message: "请选择岗位！"})
				return false
			}
			let param = this.employeeData.data
			if(type==1){//新增
				this.opAgentuserAdd({data:param,success:(res)=>{
					if(res.status==200){
						console.log('add',res)
						this.$message({type: 'success',message: res.msg})
						this.closeMask()
						this.searchData.page = 1
						this.getList()
					} else {
						this.$message({message: res.msg,type: 'error',});
					}
					this.isLoading = false
				}})
			}else if(type==2){//修改
				Object.assign(param,{id:this.employeeData.id})
				this.opAgentuserUpdate({data:param,success:(res)=>{
					if(res.status==200){
						console.log('update',res)
						this.$message({type: 'success',message: res.msg})
						this.closeMask()
						this.getList()
					} else {
						this.$message({message: res.msg,type: 'error',});
					}
					this.isLoading = false
				}})
			}
		},
		employeeStatus(row){//禁用启用
			let param = {
				id:row.id,
				status:row.status
			}
			this.opAgentuserExt({data:param,success:(res)=>{
				if(res.status==200){
					this.$message({type: 'success',message: res.msg})
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		selection(selection) {
			this.ids = selection.map(item => item.id)
		},
	}
}

</script>

<style scoped>
.wrap .wrap_body{display: flex;flex-direction: column;}
.center{display: flex;flex: 1;}
.ucenter_main{padding: 28px 0 40px;display: flex;justify-content: space-between;flex: 1;min-height: 500px;}
.right_con{width: 980px;background: #fff;}
.rc_table{width: 100%;margin-top: 20px}
.rc_main{padding: 36px 40px 46px;}
.select_wrap{margin-top: 24px;display: flex;flex-wrap: wrap;}
.select_wrap .mr{margin-right: 15px;}
.select_wrap .w290{width: 290px;margin-top: 14px;margin-right: 15px;position: relative;}
.select_wrap .w290:nth-child(3n){margin-right: 0;}
.el-cascader{line-height: 34px;}
.select_wrap .label_title{width: 76px;height: 100%;font-size: 12px;color: #999;display: flex;align-items: center;padding-left: 6px;}
.totals_btn{margin-top: 14px;display: flex;align-items: center;}
.tree_checkall{margin-top: 12px;}
.tree_box{padding: 10px;width: 100%;border: 1px solid #DCDFE6;border-radius: 4px;box-sizing: border-box;max-height: 250px;overflow: hidden auto;margin-top: 12px;}
.tree_box::-webkit-scrollbar{width:4px;}
.tree_box::-webkit-scrollbar-thumb{background: #DDDDDD;border-radius: 4px;}
.tree_box::-webkit-scrollbar-track{background: #F2F2F2;border-radius: 4px;}
.w100{width: 100%;}
</style>
<style>
.el-cascader{width: 100%;}
.select_wrap .el-button{margin-top: 14px;}
.select_wrap .el-input--prefix .el-input__inner,.select_wrap .el-cascader .el-input .el-input__inner{padding-left: 76px !important;height: 34px;line-height: 34px;font-size: 12px !important;}
.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus{border-color: var(--red);}
.el-select-dropdown__item.selected,.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path{color: var(--red);}
.select_wrap .el-input--prefix .el-input__inner:focus{border-color: var(--red);}
.el-input__prefix, .el-input__suffix{display: flex;align-items: center;}
.el-button{color: #333;border-color: #ddd;}
.el-button--small{padding: 7px 15px;}
.el-checkbox{color: #666;display: flex;align-items: center;}
.el-checkbox__inner{border-color: #ddd;width: 16px;height: 16px;}
.el-checkbox__input{display: flex;}
.el-checkbox__label{font-size: 12px;line-height: 1;}
.el-checkbox__inner::after{height: 9px;left: 5px;}
.el-checkbox__input.is-indeterminate .el-checkbox__inner::before{height: 4px;}
.totals_btn .el-button--primary.is-plain{border-color: #fff;}

/*red border color*/
.el-button.is-plain:focus, .el-button.is-plain:hover ,.el-checkbox__inner:hover ,.el-checkbox__input.is-focus .el-checkbox__inner,.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-pagination__jump .el-input__inner:focus,.el-input.is-focus .el-input__inner,.el-input .el-input__inner:focus,.el-cascader .el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner{border-color: var(--red);}

/*red color*/
.el-button.is-plain:focus, .el-button.is-plain:hover, .el-checkbox__input.is-checked+.el-checkbox__label, .el-pager li.active ,.el-pagination button:hover ,.el-pager li:hover{color: var(--red);}

/*red background color*/
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color: var(--red);}

.el-button:focus, .el-button:hover{border-color: var(--red);color: var(--red);background:#fff;}
.el-button--primary{background: var(--red) !important;color: #fff !important;border-color: #fff;}
.el-button--primary:focus, .el-button:hover{border-color: var(--red);}
.el-loading-spinner .el-loading-text{color:var(--red);}
.el-loading-spinner .path{stroke: var(--red);}
.rc_table.el-table th.el-table__cell>.cell{padding: 0 14px;}
</style>